export interface IconChevronUpProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconChevronUp = ({
  color,
  size,
  ...props
}: IconChevronUpProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 25 24" {...props} >
      <path d="M6.75 14L8.16 15.41L12.75 10.83L17.34 15.41L18.75 14L12.75 8L6.75 14Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};

import { type MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  fontFamily: 'Inter, Arial, sans-serif',
  lineHeight: 1.3,

  colors: {
    orange: [
      '#FFF2EE',
      '#FFC5B4',
      '#FF9E83',
      '#FC7D5A',
      '#F76138',
      '#F04A1C',
      '#E03809',
      '#C72E03',
      '#B22600',
      '#9D1F00'
    ],
    dark: ['#282828']
  },
  primaryColor: 'orange',

  globalStyles: (theme) => ({
    '*, *::before, *::after': {
      boxSizing: 'border-box'
    },

    html: {
      fontSize: '14px'
    },
    body: {
      ...theme.fn.fontStyles(),
      backgroundColor: '#fafafa'
    },
    'button, input, optgroup, select, textarea': {
      ...theme.fn.fontStyles()
    }
  }),
  components: {
    Container: {
      defaultProps: {
        sizes: {
          xs: 540,
          sm: 720,
          md: 960,
          lg: 1140,
          ml: 1215,
          xl: 1366
        }
      }
    }
  }
};
